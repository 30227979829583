<template>
  <div class="py-8 sm:py-12 border-b border-border last:border-0">
    <div class="flex">
      <div class="h-64 w-64 sm:h-[72px] sm:w-[72px] flex-shrink-0 rounded flex justify-center items-center bg-grey200 mr-16">
        <GlobalsLinkHelper
          :to="item.url"
          class="block"
        >
          <nuxt-img
            v-if="item.imageUrl"
            provider="norce"
            :src="item.imageUrl"
            preset="standard"
            sizes="sm:380px md:420px"
            class="h-[2.75rem] w-[2.75rem] sm:h-48 sm:w-48 object-fit"
          />
        </GlobalsLinkHelper>
      </div>
      <div class="w-full flex flex-col justify-between py-2">
        <div class="flex justify-between mb-2">
          <div class="text-xsmall-medium sm:text-small-medium pr-12">
            {{ item.name }}
          </div>
          <div>
            <TrashIcon class="h-16 w-16 select-none cursor-pointer" @click="deleteItem" />
          </div>
        </div>
        <div class="text-xsmall smm:text-small-regular mb-4">
          {{ item.packageSize > 1 ? `${item.packageSize}-pack` : '' }}
        </div>
        <div class="flex justify-between items-center">
          <div class="flex border border-border rounded">
            <div
              class="px-8 border-r border-border cursor-pointer select-none"
              :class="{
                'text-grey400 pointer-events-none': quantityLoading
              }"
              @click="setQuantity(-1)"
            >
              -
            </div>
            <div class="px-8 pt-4 text-xs">
              {{ displayedQuantity }}
            </div>
            <div
              class="px-8 border-l border-border cursor-pointer select-none"
              :class="{
                'text-grey400 pointer-events-none': quantityLoading
              }"
              @click="setQuantity(1)"
            >
              +
            </div>
          </div>
          <div v-if="item.originalPrice" class="text-xsmall-medium sm:text-small-medium">
            {{ formatPrice(item.originalPrice) }}
          </div>
        </div>
      </div>
    </div>
    <GlobalsInformationNotice
      v-if="itemOnlineOnly && (deliveryType === 'InStore' || cartStockConflict || (!canDeliverToStore && cartContainsProductOnlineAndStore && !cartLoading)) && cartType === CartType.Standard"
      :notice="productResources.productOnlineStockOnly"
      :icon="'information-circle'"
      :slim-style="isCheckout"
      class="leading-xs mb-6 mt-8 !text-xsmall sm:!text-xsmall"
      :class="{
        'bg-orange': !isCheckout
      }"
    />
    <GlobalsInformationNotice
      v-if="itemInStoreOnly"
      :notice="productResources.productInStoreStockOnly + storeNamesString"
      :icon="'information-circle'"
      :slim-style="isCheckout"
      class="leading-xs mb-6 mt-8 !text-xsmall sm:!text-xsmall"
      :class="{
        'bg-orange': !isCheckout
      }"
    />
    <GlobalsInformationNotice
      v-if="itemInStoreAndOnline && (deliveryType === 'InStore' || cartStockConflict || (!canDeliverToStore && cartContainsProductOnlineAndStore && !cartLoading)) && cartType === CartType.Standard"
      :notice="inStockStoresLength > 0 ? productResources.productInStoreAndOnline + storeNamesString : productResources.productOnlineStockOnly"
      :icon="'information-circle'"
      :slim-style="isCheckout"
      class="leading-xs mb-6 mt-8 !text-xsmall sm:!text-xsmall"
      :class="{
        'bg-orange': !isCheckout
      }"
    />
    <button
      v-if="cartStockConflict"
      class="btn btn--secondary w-full mt-8"
      @click="deleteItem"
    >
      <TrashIcon class="h-16 w-16 select-none cursor-pointer mr-6" />
      {{ productResources.remove }}
    </button>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useCartStore } from '~/store/cart';
import { CartType, type ICartItem, type IVariantAvailability } from '~/api-types';
import TrashIcon from '@heroicons/vue/24/outline/esm/TrashIcon';
import debounce from 'lodash/debounce';
import { useGlobalContentStore } from '~/store/globalContent';
import useFormatPrice from '~/composables/useFormatPrice';
import { usePageContentStore } from '~/store/pageContent';
const { formatPrice } = useFormatPrice();
const globalContentStore = useGlobalContentStore();
const checkoutSettings = useGlobalContentStore().checkoutSettings;
const productResources = globalContentStore.productResources;
const sharedResources = globalContentStore.sharedResources;
const pageStore = usePageContentStore();
const marketSettings = useGlobalContentStore().marketSettings;
const { apiGet } = useApiFetch();
const storeNamesString = ref('');
const itemOnlineOnly = ref();
const itemInStoreOnly = ref();
const itemInStoreAndOnline = ref();
const pageType = ref(useGlobalContentStore().pageType);
const productAvailableInStore = ref();
const inStockStoresLength = ref();
const quantityLoading = ref();

const props = defineProps<{
  item: ICartItem,
  cartType: CartType,
  cartStockConflict?: boolean,
  deliveryType: string,
  canDeliverToStore?: boolean,
  cartContainsProductOnlineAndStore?: boolean,
  cartLoading?: boolean
}>();

const emit = defineEmits<{
  (event: 'item-online-only', value: boolean): void,
}>();

const cartStore = useCartStore();

const isCheckout = computed(() => {
  return pageStore.pageType.slice(-1)[0] === 'CheckoutPage';
});

const isCheckoutConfirmationPage = computed(() => {
  return pageType.value === 'CheckoutConfirmationPage';
});

const fetchProductAvailability = async() => {
  const query = `product/availability?PartNo=${props.item.id}&PricelistId=${props.item.priceListId}&QuantityInCart=${props.item.quantity}&countryCode=${marketSettings.countryCode}&language=${marketSettings.contentLanguage}`;
  const res = await apiGet<[IVariantAvailability]>(query);
  
  // Get store names where product is in store stock & !disableClickAndCollect
  const inStockStoreNames: string[] = [];
  if (res) {
    res.forEach(item => {
      if (item.variantId === props.item.variantId) {

        const productAvailableOnline = item.online.inStock;
        productAvailableInStore.value = item.stores.some(obj => obj.availability.inStock === true);

        itemOnlineOnly.value = !productAvailableInStore.value && productAvailableOnline && checkoutSettings.enableClickAndCollect;
        itemInStoreOnly.value = productAvailableInStore.value && !productAvailableOnline && checkoutSettings.enableClickAndCollect;
        itemInStoreAndOnline.value = productAvailableInStore.value && productAvailableOnline && checkoutSettings.enableClickAndCollect;

        // Emit event to checkout page to show/hide delivery options
        emit('item-online-only', itemOnlineOnly.value);

        if (productAvailableInStore.value) {
          item.stores.forEach(store => {
            if (store.availability.inStock && !store.disableClickAndCollect && !inStockStoreNames.includes(store.name)) {
              inStockStoreNames.push(store.name);
            }
          });
        }
      }
    });

    inStockStoresLength.value = inStockStoreNames.length;

    // Handle different cases of the number of store names
    if (inStockStoreNames.length === 1) {
      storeNamesString.value = inStockStoreNames[0];
    } else if (inStockStoreNames.length === 2) {
      storeNamesString.value = inStockStoreNames.join(` ${sharedResources.and} `);
    } else if (inStockStoreNames.length > 2) {
      storeNamesString.value = inStockStoreNames.slice(0, -1).join(', ') + `, ${sharedResources.and} ` + inStockStoreNames[inStockStoreNames.length - 1];
    }
    storeNamesString.value = ` ${sharedResources.in} ${storeNamesString.value}`;
  }
};

const displayedQuantity = ref(props.item.quantity || 0);

const setQuantity = (modification: number) => {
  if (displayedQuantity.value + modification >= 0) {
    displayedQuantity.value = displayedQuantity.value + modification;
    updateQuantityDebounced(displayedQuantity.value);
  }
};

const updateQuantity = async(newQuantity: number) => {
  quantityLoading.value = true;
  updateItem(newQuantity);
  await fetchProductAvailability();
};

const updateQuantityDebounced = debounce(updateQuantity, 500);

const deleteItem = () => {
  // Set cartType from lastUpdated if user updates from checkout confirmation page
  const useCartType = isCheckoutConfirmationPage.value ? cartStore.lastUpdatedCart : props.cartType;
  
  cartStore.updateItem({
    partNo: props.item.partNo,
    lineNo: props.item.lineNo,
    quantity: 0,
    cartType: useCartType,
    product: props.item,
    deliveryType: props.deliveryType,
  });
};

const updateItem = async(quantity: number) => {
  // Set cartType from lastUpdated if user updates from checkout confirmation page
  const useCartType = isCheckoutConfirmationPage.value ? cartStore.lastUpdatedCart : props.cartType;

  try {
    await cartStore.updateItem({
      partNo: props.item.partNo,
      lineNo: props.item.lineNo,
      quantity: quantity,
      cartType: useCartType,
      product: props.item,
      deliveryType: props.deliveryType,
    });
  } catch (e) {
    console.log(e);
  } finally {
    displayedQuantity.value = props.item.quantity;
    quantityLoading.value = false;
  }
};

onMounted(async()=> {
  setTimeout(async() => {
    await fetchProductAvailability();
  }, 10);
});

</script>
